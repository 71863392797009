import React from 'react'

import { Text } from '@ocs.lab/ui'
import { Link } from '@ocs.lab/ui'

import { FlexFill, Flex } from '@ocs.lab/ui'

const Footer = () => {
  return (
    <footer>
      <FlexFill margin="auto" maxWidth={1600}>
        <FlexFill justifyContent="space-between" mx={24}>
          <Flex alignItems="center">
            {/* <Link color="primary" underline="hover" href={process.env.REACT_APP_ABOUT_FEEDBACK} target="_blank">О проекте FeedBack.OCS</Link> */}
            {/* <Box bgcolor={palette.text.secondary} width="1px" height={16} mx={16} /> */}
            <Flex alignItems="center">
              <Text>Есть вопросы и предложения?&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              <Text>Пиши нам на</Text>&nbsp;
              <Link color="primary" underline="hover" href={process.env.REACT_APP_ROCKET_SUPPORT_MAIL} target="_blank">
                почту команды поддержки&nbsp;
              </Link>
            </Flex>
            <Text>или&nbsp;</Text>
            <Link color="primary" underline="hover" href={process.env.REACT_APP_CHAT_SUPPORT} target="_blank">
              Даниилу Скрыльникову
            </Link>
          </Flex>
          <Flex alignItems="center">
            <Text>Потратить лавы можно в&nbsp;</Text>
            <Link color="primary" underline="hover" href={process.env.REACT_APP_OSIP_MARKET} target="_blank">
              магазинчике Осипа
            </Link>
          </Flex>
        </FlexFill>
      </FlexFill>
    </footer>
  )
}

export default Footer